import { useState, useEffect } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { getCompaniesWithServices } from "../../../../services/PsidService";
import { paymentIntimationConfiguration } from "../../../../services/ActiveIntimationPayment";
function ActivateIntimation() {
  const updateHeaderContext = useUpdateHeaderContext();

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [oldOne, setOldOne] = useState(true);

  const getReportData = async () => {
    setLoading(true);

    const data = await getCompaniesWithServices();
    setServices(data?.data?.content);
    setLoading(false);
  };

  const [visibleServices, setVisibleServices] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleServiceVisibility = (companyId: string) => {
    setVisibleServices((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };

  const activateIntimation = (serviceId: number, isActive: boolean) => {
    setOldOne(false);
    paymentIntimationConfiguration({ serviceId, isActive });
    setServices((currentServices: any) =>
      currentServices.map((company: any) => ({
        ...company,
        services: company.services.map((service: any) => {
          if (service.id === serviceId) {
            return { ...service, isIntimationAllowed: isActive };
          }
          return service;
        }),
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    if (oldOne) {
      if (searchInput === "") {
        setVisibleServices({});
      } else {
        const newVisibleServices = services.reduce((acc: any, company: any) => {
          const isVisible = company.services.some((service: any) =>
            service.title.toLowerCase().includes(searchInput.toLowerCase())
          );
          if (isVisible) {
            acc[company.id] = true;
          }
          return acc;
        }, {});
        setVisibleServices((prevState) => ({
          ...prevState,
          ...newVisibleServices,
        }));
      }
    }
  }, [searchInput, services]);

  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    if (oldOne) {
      const newVisibleServices = services.reduce((acc: any, company: any) => {
        acc[company.id] = false;
        return acc;
      }, {});
      setVisibleServices(newVisibleServices);
    }
  }, [services, oldOne]);

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Activate Intimation" });
  }, []);

  const renderCompany = (company: any) => {
    return (
      <div key={company.id} className="mb-4">
        <div
          className="p-4"
          style={{
            backgroundColor: "white",
          }}
        >
          <h6
            onClick={() => toggleServiceVisibility(company.id.toString())}
            style={{
              cursor: "pointer",
              justifyContent: "space-between",
              display: "flex",
              fontWeight: "600",
            }}
          >
            {company?.title}
            {visibleServices[company.id] ? (
              <i className="fa fa-angle-up" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            )}
          </h6>
          {visibleServices[company.id] && (
            <div>
              <div>
                {company?.services?.map((service: any) => {
                  if (
                    service?.title
                      ?.toLocaleLowerCase()
                      ?.startsWith(searchInput?.toLocaleLowerCase())
                  ) {
                    return (
                      <div
                        key={service?.id}
                        className="d-flex justify-content-between py-4"
                        style={{
                          borderBottom: "1px solid #E0E2E7",
                        }}
                      >
                        <div>{service?.title}</div>
                        <div>
                          <div className="switch_box">
                            <input
                              type="checkbox"
                              className="switch_1"
                              checked={service?.isIntimationAllowed}
                              onChange={() => {
                                if (!loading) {
                                  activateIntimation(
                                    service.id,
                                    !service.isIntimationAllowed
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-middle-content">
      <div className="d-flex justify-content-between items-center my-4">
        <div>
        <h6 style={{ fontWeight: "bold", fontSize: "24px" }}>Intimation Activation</h6>
          <div>
            We can enable and disable Intimation for the services given below
          </div>
        </div>
        <div className="col-md-4 p-0">
  <div className="form-group has-search" style={{ position: "relative" }}>
    <span
      className="fa fa-search form-control-feedback"
      style={{
        position: "absolute",
        right: "10px",
        top: "50%", 
        transform: "translateY(-50%)",
        color: "#aaa", 
        pointerEvents: "none",
      }}
    ></span>
    <input
      type="text"
      className="form-control"
      placeholder="Search for a Company or Service"
      style={{ paddingRight: "2.5rem" }} 
      value={searchInput}
      onChange={(e) => {
        setOldOne(true);
        setSearchInput(e.target.value);
      }}
    />
  </div>
</div>

      </div>
      {loading ? (
        <div
          className="w-100"
          style={{ textAlign: "center", marginTop: "200px" }}
        >
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            {services?.map((company: any, index) => {
              const filteredServices = company?.services?.filter(
                (service: any) =>
                  service?.title
                    ?.toLocaleLowerCase()
                    ?.startsWith(searchInput?.toLocaleLowerCase())
              );
              if (filteredServices.length > 0 && index < services?.length / 2) {
                return renderCompany(company);
              } else {
                return null;
              }
            })}
          </div>
          <div className="col-md-6">
            {services?.map((company: any, index) => {
              const filteredServices = company?.services?.filter(
                (service: any) =>
                  service?.title
                    ?.toLocaleLowerCase()
                    ?.startsWith(searchInput?.toLocaleLowerCase())
              );
              if (filteredServices.length > 0 && index > services?.length / 2) {
                return renderCompany(company);
              } else {
                return null;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ActivateIntimation;
