import { useFormik } from "formik";
import { useEffect, useState, useMemo, useCallback } from "react";
import FullName_user from '../../../../../src/assets/images/FullName_user.png'
import Lock_permission from '../../../../assets/images/lock_permission.png'
import plus from "../../../../../src/assets/images/Vector.png" 
import * as Yup from "yup";
import {
  getPermissions,
  savePermissions,
  updatePermissionsStatus,
  updatePermissions,
} from "./../../../services/UserService";
import { Modal } from "react-bootstrap";
import clsx from "clsx";
import classes from "./user-management/styles.module.css";
import { useUpdateHeaderContext } from "../../../contexts/HeaderContext";
import { Dropdown } from "react-bootstrap";

const submitScheme = Yup.object().shape({
  displayName: Yup.string()
    .min(3, "Minimum 3 character")
    .max(20, "Maximum 20 character")
    .required("displayName is required"),
  permissionType: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(20, "Maximum 20 symbols")
    .required("permissionType is required"),
});

const initialValues = {
  displayName: "",
  permissionType: "",
};

function UserPermissions() {
  const [permissions, setPermissions] = useState<any>([]);
  const updateHeaderContext = useUpdateHeaderContext();

  const [isSending, setIsSending] = useState(false);
  const [permissionForm, setPermissionForm] = useState(false);
  const [updateUserTitle, setUpdateUserTitle] = useState(false);
  const sendStatusRequest = useCallback(
    async (id: any, status: any) => {
      // don't send again while we are sending
      if (isSending) return;
      const postdata = {
        id: id,
        active: status,
      };
      // update state
      setIsSending(true);
      // send the actual request
      await updatePermissionsStatus(postdata);
      // once the request is sent, update state again
      setIsSending(false);
    },
    [isSending]
  ); // update the callback if the state changes

  const [loading, setLoading] = useState(false);
  const [isPermissionUpdate, setPermissionUpdate] = useState(false);
  const [permissionId, setPermissionID] = useState(0);

  const formik = useFormik({
    initialValues,
    validationSchema: submitScheme,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      var perId = 0;
      try {
        if (isPermissionUpdate) {
          perId = permissionId;
        }
        const postdata = {
          id: perId,
          permissionType: values.permissionType,
          displayName: values.displayName,
        };
        if (isPermissionUpdate) {
          await updatePermissions(postdata);
        } else {
          await savePermissions(postdata);
        }
        setSubmitting(false);
        setLoading(false);
        getPermissionsList();
        resetForm();
        setPermissionUpdate(false);
        setPermissionID(0);
        setPermissionForm(false);
      } catch (error) {
        console.error(error);
        // saveAuth(undefined)
        setStatus("The fail to submit");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const updateValues = (permission: any) => {
    showPermissionForm();
    formik.values.displayName = permission.displayName;
    formik.values.permissionType = permission.permissionType;
    setPermissionUpdate(true);
    setPermissionID(permission.id);
    setUpdateUserTitle(true);
  };

  const getPermissionsList = async () => {
    const response = await getPermissions();
    setPermissions(response.data.content);
  };
  const updateStatus = async (id: any, status: any) => {
    const postData = {
      id: id,
      activated: status,
    };
    const { data } = await updatePermissionsStatus(postData);
    getPermissionsList();
  };

  const showPermissionForm = () => {
    resetForm();
    setPermissionForm(true);
  };

  const resetForm = () => {
    formik.values.displayName = "";
    formik.values.permissionType = "";
  };

  var collapseAbleHandler = () => {
    // const element = document.getElementById("collapseButton")
    // element?.click()
    setPermissionForm(false);
    setUpdateUserTitle(false);
  };

  var rows =
    permissions &&
    permissions.length > 0 &&
    permissions.map((row: any, index: any) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          {/* <td>{row.id}</td> */}
          <td>{row.displayName}</td>
          <td>{row.permissionType}</td>
          <td className="">
            {/* <button
              className={
                row.active ? "btn btn-active" : "btn btn-inactive"
              }
              onClick={async () => {
                await updateStatus(row.id, !row.active);
              }}
            >
              
            </button> */}
            <span
      style={{
        fontWeight: 'bold',
        color: row.active ? '#48C499' : 'black',
      }}
    >
      {row.active ? "ACTIVE" : "INACTIVE"}
    </span>
          </td>
          <td className="">
            {/* <button
              className="btn btn-edit"
              onClick={() => updateValues(row)}
            >
              Edit
            </button> */}
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                size="sm"
                className={classes.dropdownToggleAfter}
              >
                <i
                  className="bi bi-three-dots-vertical icon-red"
                  style={{ color: "green" }}
                ></i>
              </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateValues(row)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  await updateStatus(row.id, !row.active);
                }}
              >
                {row.active ? "Active" : "Inactive"}
              </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
          </td>
        </tr>
      );
    });

  useEffect(() => {
    updateHeaderContext({ pageTitle: "User Permissions" });
  }, []);

  useEffect(() => {
    getPermissionsList();
  }, []);

  return (
    <div className="dashboard-middle-content">
      <div className="col-md-12">
        <div className="">
          <div className="white-bg">
            <div className="float-right">
              <button
                className={`mb-3 ${classes.addRoleButton}`}
                onClick={showPermissionForm}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                    <img
          src={plus}
          alt="Add User"
          style={{
            marginRight:'9px',
            width:'9%',
            height:'auto',
            marginTop:'-2px'
          }}
        />
              <span>  Add Permissions</span>
              </button>
            </div>
          </div>
          {permissionForm && (
            <Modal
              size="lg"
              style={{ width: "800px", margin: "auto", marginTop: "100px" }}
              show={permissionForm}
              onHide={() => setPermissionForm(false)}
            >
              <div className="modal-content p-2">
                <div style={{ backgroundColor: "white", padding: "30px" }}>
                <h3 className="text-center mb-5" style={{ fontWeight:'600' }}>{updateUserTitle ? "Edit Permissions" : "Add Permissions"}</h3>
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id="login_form"
                  >
                    {formik.status ? (
                      <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">
                          {formik.status}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="row">
                      <div className="col-md-6 mb-3">
                      <img src={FullName_user} alt="" style={{display:"inline-block", position:'absolute', left:'22px' , top:'10px'  ,zIndex:'2'}} />
                        <input
                           style={{
                            padding:"1.375rem 2.4rem"
                          }}
                          className={clsx(
                            "w-100 form-control",
                            { "classes.dashboard-top-filter-input": true },
                            {
                              "is-invalid":
                                formik.touched.displayName &&
                                formik.errors.displayName,
                            },
                            {
                              "is-valid":
                                formik.touched.displayName &&
                                !formik.errors.displayName,
                            }
                          )}
                          {...formik.getFieldProps("displayName")}
                          type="text"
                          id="displayName"
                          name="displayName"
                          autoComplete="off"
                          placeholder="Display Name"
                        />
                        {formik.touched.displayName &&
                          formik.errors.displayName && (
                            <div className="invalid-feedback">
                              {" "}
                              {formik.errors.displayName}{" "}
                            </div>
                          )}
                        {/* <input className="form-control RaastInput" type="text" placeholder="Name"/> */}
                      </div>
                      <div className="col-md-6 mb-3">
                        <img src={Lock_permission} alt=""  style={{display:"inline-block", position:'absolute', left:'22px' , top:'10px'  ,zIndex:'2'}}/>
                        <input
                           style={{
                            padding:"1.375rem 2.4rem"
                          }}
                          className={clsx(
                            "w-100 form-control",
                            {
                              "is-invalid":
                                formik.touched.permissionType &&
                                formik.errors.permissionType,
                            },
                            {
                              "is-valid":
                                formik.touched.permissionType &&
                                !formik.errors.permissionType,
                            }
                          )}
                          {...formik.getFieldProps("permissionType")}
                          type="text"
                          id="permissionType"
                          name="permissionType"
                          autoComplete="off"
                          placeholder="Permission Type"
                        />
                        {formik.touched.permissionType &&
                          formik.errors.permissionType && (
                            <div className="invalid-feedback">
                              {" "}
                              {formik.errors.permissionType}{" "}
                            </div>
                          )}
                      </div>
                      <div className="clearfix"></div>
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className="float-right mt-4">
                          <button
                            className={`${classes.cancelButton} mr-4`}
                            type="button"
                            onClick={collapseAbleHandler}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            id="sign_in_submit"
                            className={`${classes.saveButton}`}
                            disabled={formik.isSubmitting || !formik.isValid}
                          >
                            {!loading && (
                              <span className="indicator-label">Save</span>
                            )}
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
      {permissions.length > 0 &&(
        <div style={{ margin: "20px" }}>
          <table>
            <thead>
              <tr>
                <th>Sr#</th>
                {/* <th>Id</th> */}
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UserPermissions;
