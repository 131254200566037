import moment from "moment";
import { CellProps } from 'react-table'; 
import { useMemo, useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { dt } from "../../../../helpers/AppHelpers";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";

import { Button, Modal } from "react-bootstrap";
import { numberWithCommas } from "../../../../utils/Utility";
import {
  getRevertedTransactions,
  revertTransaction,
} from "../../../../services/RevertTransaction";
import {
  RevertedTransactionFilter,
  RevertedTransactionResponse,
} from "../../models/interfaces/RevertTransaction";
import FilterBox from "../../reusableComponents/FilterBox";
import ConsumerNumber from "../../reusableComponents/ConsumerNumber";
import FilterBoxSelectedDate from "../../reusableComponents/FilterBoxSelectedDate";

function RevertTransaction() {
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [loader, _] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const initialFilter: RevertedTransactionFilter = {
    startDate: _newDate,
    endDate: _newDate,
    pageIndex: currentPage - 1,
    pageSize: 10,
    consumerNumber: "",
  };
  const [selectedFilter, setSelectedFilter] = useState<any>({ startDate: _newDate, endDate: _newDate });
  const isFirstRender = useRef(true);
  const [filter, setFilter] =
    useState<RevertedTransactionFilter>(initialFilter);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const getReportData = async (init: boolean = false) => {
    if (moment(filter.startDate).isAfter(filter.endDate) && !init) {
      Swal.fire({
        icon: "error",
        title: "Date Range Error",
        text: "From Date can not be greater than To Date",
      });
      return;
    }
    setPageData((prev: any) => ({ ...prev, isLoading: true }));
    setSelectedFilter(init ? initialFilter : filter);
    const response = await getRevertedTransactions(
      init
        ? {
            ...initialFilter,
            startDate: `${initialFilter.startDate} 00:00:00`,
            endDate: `${initialFilter.endDate} 23:59:59`,
          }
        : {
            ...filter,
            pageSize: Number(filter.pageSize),
            startDate: `${filter.startDate} 00:00:00`,
            endDate: `${filter.endDate} 23:59:59`,
          }
    );
    if (response.status === 200) {
      const { content, message } = response.data;
      const psidObj: RevertedTransactionResponse[] = content;
      setPageData((prevState: any) => ({
        ...prevState,
        isLoading: false,
        rowData: psidObj,
        totalPages: 10,
        totalRows: Number(message),
      }));
    }
  };

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const onChangeStartDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  function onSearchChange(value: any) {
    setFilter((filter: any) => ({
      ...filter,
      consumerNumber: value,
      pageIndex: 0,
    }));
  }

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
    setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
    setFilter((filter: any) => ({
      ...filter,
      pageSize: pageItem,
      pageIndex: 0,
    }));
  };

  const handleRevertClick = (rowData: any) => {
    setSelectedRowData(rowData);
    setShowConfirmationModal(true);
  };

  const handleRevert = () => {
    if (
      selectedRowData &&
      selectedRowData?.psidId &&
      selectedRowData?.consumerNumber
    ) {
      revertTransaction(
        selectedRowData?.psidId,
        selectedRowData?.consumerNumber
      )
        .then(() => {
          setShowConfirmationModal(false);
          getReportData();
          Swal.fire({
            icon: "success",
            title: "Transaction Reverted",
            text: "Transaction is reveted successfully",
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Transaction Reverted Failed",
            text: "Transaction is not reverted",
          });
          setShowConfirmationModal(false);
        });
    }
  };

  let columns: any = useMemo(() => {
    let columns = [
      {
        Header: "PSID ID",
        accessor: "psidId",
        Cell: ({ value }: { value: string }) => (
          <div style={{ color: "#6583E8", fontWeight: "bold" }}>{value}</div>
        ),
      },
      {
        Header: "Consumer Number",
        accessor: "consumerNumber",
      },
      {
        Header: "Response Code",
        accessor: "responseCode",
      },
      {
        Header: "Bill Status",
        accessor: "billStatus",
      },
      {
        Header: "Transaction Date",
        accessor: "transactionDate",
      },
      {
        Header: "Transaction Time",
        accessor: "transactionTime",
      },
      {
        Header: "Transaction Amount",
        accessor: "transactionAmount",
            Cell: ({ value }: CellProps<any>) => {
                
                const formattedValue = Number(value).toLocaleString();
                const [integerPart, decimalPart] = formattedValue.split('.');
          
                return (
                  <>
                    <strong>{integerPart}</strong>
                    {decimalPart && <span>.{decimalPart}</span>}
                  </>
                );
              }
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }: any) => (
          <Button
            variant="danger"
            onClick={() => handleRevertClick(row.original)}
          >
            Revert
          </Button>
        ),
      },
    ];

    return columns;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      getReportData();
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Revert Transactions" });
  }, []);

  return (
    <div className="dashboard-middle-content">
      {loader || pageData.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
      ) : (
        <>
        <div className="position-relative">
            <FilterBoxSelectedDate selectedFilter={selectedFilter}/>
          </div>
          <FilterBox _initFilters={_initFilters} getReportData={getReportData}>
            <div className="w-100">
              <ConsumerNumber
                search={filter?.consumerNumber}
                onSearchChange={onSearchChange}
              />
            </div>
            <div className="w-100">
              <input
                className="dashboard-top-filter-input mb-2"
                type="date"
                value={filter?.startDate || ""}
                onChange={onChangeStartDate}
              />
            </div>
            <div className="w-100">
              <input
                className="dashboard-top-filter-input"
                type="date"
                value={filter?.endDate || ""}
                onChange={onChangeEndDate}
              />
            </div>
          </FilterBox>
          {pageData?.rowData && (
            <>
              <div className="card">
                <AppTable
                  columns={columns}
                  data={pageData.rowData.map((row: any) => ({
                    ...row,
                    transactionAmount: numberWithCommas(row.transactionAmount),
                  }))}
                  isLoading={pageData.isLoading || loader}
                />
              </div>
              <Pagination
                totalRows={pageData.totalRows}
                pageChangeHandler={setPageIndex}
                rowsPerPage={filter.pageSize}
                currentPage={currentPage}
                pageItemChangeHandler={onLoadPageItems}
              />
            </>
          )}
          <Modal
            show={showConfirmationModal}
            onHide={() => setShowConfirmationModal(false)}
            centered
          >
            <Modal.Header closeButton={false}>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to revert this transaction?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowConfirmationModal(false)}
              >
                No
              </Button>
              <Button variant="primary" onClick={handleRevert}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
  
}

export default RevertTransaction;
