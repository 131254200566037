import moment from "moment";
import React from "react";
import { useEffect, useState, useMemo } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { dt } from "../../../../helpers/AppHelpers";
import {
  getTransactionSummary,
  getTransactionSummaryAccountWise,
} from "../../../../services/PsidService";
import {
  ITransactionSummaryResponse,
  ITransactionSummaryFilter,
  ICompanyTransactionSummary,
  IServiceSettlementResponse,
} from "../../models/interfaces";
import { useAuth } from "../../../auth/core/Auth";
import { IServiceBifurcationResponse } from "../../models/interfaces/IServiceBifurcationResponse";
import FilterBox from "../../reusableComponents/FilterBox";
import FilterBoxSelectedDate from "../../reusableComponents/FilterBoxSelectedDate";

function TransactionSummary() {
  const { auth } = useAuth();

  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );

  const initialFilter = {
    startDate: _newDate,
    endDate: _newDate,
  };

  const [filter, setFilter] = useState<ITransactionSummaryFilter>({
    startDate: _newDate,
    endDate: _newDate,
  });
  const [selectedFilter, setSelectedFilter] = useState<any>({ startDate: _newDate, endDate: _newDate });
  const [transactionSummary, setTransactionSummary] =
    useState<ITransactionSummaryResponse[]>();
  const [companyTransactionSummary, setCompanyTransactionSummary] =
    useState<ICompanyTransactionSummary[]>();
  const [totalChallanAmount, setTotalChallanAmount] = useState<Number>(0);
  const [totalPitbCharges, setTotalPitbCharges] = useState<Number>(0);
  const [transactionBifurcation, setTransactionBifurcation] =
    useState<IServiceBifurcationResponse[]>();
  const [_, setServiceBifurcation] = useState<string>("");
  const [show, setShow] = useState(false);
  const [activeDetailServiceId, setActiveDetailServiceId] = useState<
    number | null
  >(null);
  const [loader, setLoader] = useState(false);
  const [loaderItem, setLoaderItem] = useState(false); 
  const [pageData, setPageData] = useState({ isLoading: true });
  useEffect(() => {
    setLoader(true); 
    setTimeout(() => {
      setPageData({ isLoading: false }); 
      setLoader(false); 
    }, 2000); 
  }, []);
  useEffect(() => {
    updateHeaderContext({ pageTitle: "Transactions Summary" });
    getReportData();
  }, []);

  const getReportData = async (init: boolean = false) => {
    setCompanyTransactionSummary([]);
    setServiceBifurcation("");
    setShow(false);
    setTotalChallanAmount(0);
    setSelectedFilter(init ? initialFilter : filter);
    const response = await getTransactionSummary(init ? initialFilter : filter);
    if (response.status === 200) {
      const { content } = response.data;
      const transactionSummaryObj: ITransactionSummaryResponse[] = content;
      let totalChallan = 0;
      let totalCharges = 0;
      setTransactionSummary(transactionSummaryObj);
      let companySummaryList: ICompanyTransactionSummary[] = [];
      if (transactionSummaryObj.length) {
        transactionSummaryObj.forEach(
          (obj: ITransactionSummaryResponse, index) => {
            const existedCompanySummary = companySummaryList.find(
              (ecs) => ecs.companyId == obj.companyId
            );
            if (existedCompanySummary != null) {
              existedCompanySummary.companyTotalChallanAmount =
                existedCompanySummary.companyTotalChallanAmount +
                Number(obj.challanAmount);
              existedCompanySummary.companyTotalPitbCharges =
                existedCompanySummary.companyTotalPitbCharges +
                Number(obj.pitbCharges);
              existedCompanySummary.serviceTransactionSummary.push({
                serviceName: obj.serviceName,
                settlementMode: obj.settlementMode,
                challanAmount: obj.challanAmount,
                pitbCharges: obj.pitbCharges,
                serviceId: obj.serviceId,
              });
            } else {
              companySummaryList.push({
                companyId: obj.companyId,
                companyName: obj.companyName,
                companyTotalChallanAmount: Number(obj.challanAmount),
                companyTotalPitbCharges: Number(obj.pitbCharges),
                serviceTransactionSummary: [
                  {
                    serviceName: obj.serviceName,
                    settlementMode: obj.settlementMode,
                    challanAmount:  obj.challanAmount,
                    pitbCharges:  obj.pitbCharges,
                    serviceId: obj.serviceId,
                  },
                ],
              });
            }
            totalChallan =
              totalChallan +
              (obj.challanAmount ? Number(obj.challanAmount) : 0);
            totalCharges =
              totalCharges + (obj.pitbCharges ? Number(obj.pitbCharges) : 0);
            setTotalChallanAmount(totalChallan);
            setTotalPitbCharges(totalCharges);
          }
        );
        setCompanyTransactionSummary(companySummaryList);
      }
    }
  };

  const handleShow = async (companyId: number) => {
    setLoaderItem(true);
    setTransactionBifurcation([]);
    setShow(true);
    setActiveDetailServiceId(companyId);
    // setServiceBifurcation(serviceName);
    const response = await getTransactionSummaryAccountWise({
      startDate: filter.startDate,
      endDate: filter.endDate,
      companyId: companyId,
    });
    if (response.status === 200) {
      const data = response.data.content;
      setTransactionBifurcation(data);
      // console.log(data);
      setShow(true);
      setActiveDetailServiceId(companyId);
      setLoaderItem(false);
    }
  };

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const onChangeStartDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  const statusChangeHandler = (e: any) => {
    const transactionType = e.target.value;
    setFilter((filter: any) => ({ ...filter, transactionType }));
  };
  var newRows = ()=> {
      return (
        <tr>
          <td colSpan={4}>
             <table>
           <thead>
                <tr>
                  <th>Account Name</th>
                  <th>Account Number</th>
                   <th>Settlement Amount</th>
                 </tr>
               </thead>
               <tbody>
                 {transactionBifurcation?.length != undefined &&
                  transactionBifurcation.map(
                    (tbRow: IServiceBifurcationResponse, index: any) => (
                      <tr>
                        <td>{tbRow.accountTitle}</td>
                        <td>{tbRow.accountNumber}</td>
                        <td className="text-center">
                          {tbRow.challanAmount.toLocaleString()}
                        </td>
                      </tr>
                    )
                  )}
                {transactionBifurcation?.length == 0 && (
                  <tr>
                    <td />
                    <div
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      No record found.
                    </div>
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )
  }

       



  var rows =
    companyTransactionSummary &&
    companyTransactionSummary.length > 0 &&
    companyTransactionSummary.map(
      (row: ICompanyTransactionSummary, index: any) => {
        return (
          <>
            <tr
              key={index + 50}
              onClick={() => {
                if (activeDetailServiceId !== row.companyId)
                  handleShow(row.companyId);
                else if (activeDetailServiceId === row.companyId && !show)
                  handleShow(row.companyId);
                else setShow(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <td>
                <b className="mr-4">{row.companyName}</b>
                {show && activeDetailServiceId === row.companyId ? (
                  <i className="fa fa-angle-up" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                )}
              </td>
            </tr>
            {row.serviceTransactionSummary &&
              row.serviceTransactionSummary.length > 0 &&
              row.serviceTransactionSummary.map(
                (crow: IServiceSettlementResponse, cindex: any) => {
                  return (
                    <>
                      <tr key={cindex}>
                        <td></td>
                        <td>{crow.serviceName}</td>
                        <td>{Number(crow.challanAmount).toLocaleString()}</td>
                        <td>{Number(crow.pitbCharges).toLocaleString()}</td>
                      </tr>
                    </>
                  );
                }
              )}
            {show && activeDetailServiceId === row.companyId && (
              <tr>
                <td>
                  <table>
                    <thead>
                      <tr>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Settlement Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionBifurcation?.length != undefined &&
                        transactionBifurcation.map(
                          (tbRow: IServiceBifurcationResponse, index: any) => (
                            <tr>
                              <td>{tbRow.accountTitle}</td>
                              <td>{tbRow.accountNumber}</td>
                              <td className="text-center">
                                {tbRow.challanAmount.toLocaleString()}
                              </td>
                            </tr>
                          )
                        )}
                      {transactionBifurcation?.length == 0 && (
                        <tr>
                          <td />
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            No record found.
                          </div>
                          <td />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            {row.serviceTransactionSummary &&
              row.serviceTransactionSummary.length > 1 && (
                <tr key={index}>
                  <td colSpan={2} className="text-right">
                    <b>Company Total</b>
                  </td>
                  <td>{row.companyTotalChallanAmount.toLocaleString()}</td>
                  <td>{row.companyTotalPitbCharges.toLocaleString()}</td>
                </tr>
              )}
          </>
        );
      }
    );

    return (
      <div className="dashboard-middle-content">
        {loader || pageData.isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </div>
        ) : (
          <>
          <div className="position-relative">
            <FilterBoxSelectedDate selectedFilter={selectedFilter}/>
          </div>
            <FilterBox _initFilters={_initFilters} getReportData={getReportData}>
              <div className="w-100">
                <input
                  className="dashboard-top-filter-input"
                  type="date"
                  value={filter?.startDate || ""}
                  onChange={onChangeStartDate}
                />
              </div>
              <div className="w-100">
                <input
                  className="dashboard-top-filter-input"
                  type="date"
                  value={filter?.endDate || ""}
                  onChange={onChangeEndDate}
                />
              </div>
            </FilterBox>
            
              <div className="card">
                <table>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Service Name</th>
                      <th>Company Charges</th>
                      <th>PITB Charges</th>
                    </tr>
                  </thead>
                  {companyTransactionSummary?.length ? (

                    <tbody>
                      {companyTransactionSummary.map((row, index) => (
                        <React.Fragment>
                          <tr
                            key={index + 50}
                            onClick={() => {
                              if (activeDetailServiceId !== row.companyId)
                                handleShow(row.companyId);
                              else if (activeDetailServiceId === row.companyId && !show)
                                handleShow(row.companyId);
                              else setShow(false);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{row.companyName} {"         "}
                              {show && activeDetailServiceId === row.companyId ? (
                                <i className="fa fa-angle-up" aria-hidden="true"></i>
                              ) : (
                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                              )}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td><b>{row.companyTotalChallanAmount.toLocaleString()}</b></td>
                        <td><b>{row.companyTotalPitbCharges.toLocaleString()}</b></td> */}
                          </tr>
                          {row.serviceTransactionSummary.map((row1, index) =>
                            <tr>
                              <td></td>
                              <td>{row1.serviceName}</td>
                              <td>{Number(row1.challanAmount).toLocaleString()}</td>
                              <td>{Number(row1.pitbCharges).toLocaleString()}</td>
                            </tr>
                          )}
                          {
                            <tr>
                              <td></td>
                              <td style={{ textAlign: 'right' }}><b>Company Total</b></td>
                              <td><b>{row.companyTotalChallanAmount.toLocaleString()}</b></td>
                              <td><b>{row.companyTotalPitbCharges.toLocaleString()}</b></td>
                            </tr>
                          }


                          {show && activeDetailServiceId === row.companyId && transactionBifurcation?.length != undefined && (
                            <tr>
                              <td colSpan={4}>
                                <table>
                                  <thead >
                                    <tr >
                                      <th>Account Name</th>
                                      <th>Account Number</th>
                                      <th>Settlement Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {transactionBifurcation?.length != undefined &&
                                      transactionBifurcation.map(
                                        (tbRow: IServiceBifurcationResponse, index: any) => (
                                          <tr>
                                            <td>{tbRow.accountTitle}</td>
                                            <td>{tbRow.accountNumber}</td>
                                            <td>
                                              {tbRow.challanAmount.toLocaleString()}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    {transactionBifurcation?.length == 0 && (
                                      <tr>
                                        <td />
                                        <div
                                          style={{ textAlign: "center", marginTop: "20px" }}
                                        >
                                          {loaderItem ? (
                                            <div style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              height: "10vh",
                                            }}
                                            >
                                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </div>) : (<div>No record found</div>)
                                          }

                                        </div>
                                        <td />
                                      </tr>
                                    )}

                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>

                      ))}
                      <tr>
                        <td><b>Total Charges</b></td>
                        <td></td>
                        <td><b>{totalChallanAmount.toLocaleString()}</b></td>
                        <td><b>{totalPitbCharges.toLocaleString()}</b></td>
                      </tr>
                    </tbody>
                  ) : (
                    <tfoot>
                      <tr>
                        <td className="text-center" colSpan={4}>No data found</td>
                      </tr>
                    </tfoot>
                  )}
                  
                </table>
              </div>
            
          </>
        )}
      </div>
    );
    
}

export default TransactionSummary;
